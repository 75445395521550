import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ComponentType } from '@angular/cdk/overlay';
import { BaseSnackBarData } from '@hesti/components/snack-bar-templates/base-snack-bar/base-snack-bar.model';
import { BaseSnackBarComponent } from '@hesti/components/snack-bar-templates/base-snack-bar/base-snack-bar.component';
import { AppTranslateService } from '@hesti/services/translation/app-translate.service';
import { BaseSnackBarType } from '@hesti/components/snack-bar-templates/base-snack-bar/base-snack-bar-type.enum';

@Injectable({ providedIn: 'root' })
export class SnackBarService<TTranslationKey = string> {
  private readonly duration: number = 3333;

  public constructor(
    private readonly snackBar: MatSnackBar,
    private readonly appTranslateService: AppTranslateService<TTranslationKey>,
  ) {}

  public showComponent<T, R>(component: ComponentType<T>, data: R, duration: number = this.duration): MatSnackBarRef<T> {
    return this.snackBar.openFromComponent(component, { data, verticalPosition: 'top', horizontalPosition: 'center', duration });
  }

  public showMessage(
    type: BaseSnackBarType,
    messageKey: TTranslationKey,
    messageInterpolateParams?: object | undefined,
    duration: number = this.duration,
  ): MatSnackBarRef<BaseSnackBarComponent> {
    return this.showComponent(
      BaseSnackBarComponent,
      new BaseSnackBarData(this.appTranslateService.instant(messageKey, messageInterpolateParams), type),
      duration,
    );
  }

  public showTranslatedMessage(
    type: BaseSnackBarType,
    message: string,
    duration: number = this.duration,
  ): MatSnackBarRef<BaseSnackBarComponent> {
    return this.showComponent(BaseSnackBarComponent, new BaseSnackBarData(message, type), duration);
  }
}
