import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { BaseSnackBarType } from '@hesti/components/snack-bar-templates/base-snack-bar/base-snack-bar-type.enum';
import { ThemePalette } from '@angular/material/core';
import { IconComponent } from '@hesti/components/icon/icon.component';
import { Icon } from '@hesti/components/icon/icon.enum';
import { BaseSnackBarData } from './base-snack-bar.model';

@Component({
  selector: 'ha-base-snack-bar',
  templateUrl: './base-snack-bar.component.html',
  styleUrls: ['./base-snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatSnackBarModule, MatButtonModule, IconComponent],
  standalone: true,
})
export class BaseSnackBarComponent {
  public readonly Icon = Icon;

  @HostBinding('class')
  public get type(): BaseSnackBarType {
    return this.data.type;
  }

  public get closeButtonColor(): ThemePalette {
    switch (this.type) {
      case BaseSnackBarType.Success:
        return 'primary';
      case BaseSnackBarType.Error:
        return 'warn';
      case BaseSnackBarType.Info:
        return 'accent';
    }
  }

  public constructor(
    public readonly snackBarRef: MatSnackBarRef<BaseSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public readonly data: BaseSnackBarData,
  ) {}
}
